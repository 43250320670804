@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Goudy+Bookletter+1911|Palanquin+Dark');
$primary: #ea1948;

/* MAIN COLOR */

$secondary: #960714;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
$mod_1: 1.4; // mobile
$mod_2: 1.6; // desktop
html,
body {
    height: 100%;
}

html {
    @include fluid-type($min_width,
    $max_width,
    $min_font,
    $max_font);
}

p,
ol,
ul {
    font-size: 1rem;
    font-family: 'Goudy Bookletter 1911', serif;
}

a {
    font-size: 1rem;
    font-family: 'Palanquin Dark', sans-serif;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Palanquin Dark', sans-serif;
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Palanquin Dark', sans-serif;
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2 *$min_font);
    font-family: 'Palanquin Dark', sans-serif;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbar-default {
    background: rgb(176, 212, 227);
    background: -moz-linear-gradient(top, rgb(176, 212, 227) 0%, rgb(136, 186, 207) 100%);
    background: -webkit-linear-gradient(top, rgb(176, 212, 227) 0%, rgb(136, 186, 207) 100%);
    background: linear-gradient(to bottom, rgb(176, 212, 227) 0%, rgb(136, 186, 207) 100%);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 3px;
            display: flex;
            align-items: center;
            color: $primary;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $primary;
                outline: 0;
            }
            &:hover {
                background: transparent;
                border-radius: 5px;
                color: $secondary;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    @media (max-width: 767px) {
        margin: 11px 15px 8px 0px;
    }
}


/** LOGIN FORM **/

@mixin btn1 {
    text-align: center;
    margin: 0 auto;
    border: 1px solid $primary;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        box-shadow: 0px 0px 3px lighten($blk, 10%);
    }
}

@mixin btn2 {
    @include btn1;
    background: darken($wht, 5%);
    color: $primary;
    border: none;
    &:hover {
        background: $wht;
        color: $primary;
        border: none;
    }
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: none;
        border-bottom: 1px solid darken($primary, 20%);
        text-align: center;
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn1;
        display: block;
        width: 75%;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

@media (max-width: 1024) {
    input#username,
    input#password {
        font-size: 16px;
    }
}


/* ---------- Header ------------ */

.header {
    background-image: url("../img/banner.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 80% 0%;
    z-index: -1000;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-color: #fff;
    margin-top: -50px;
    @media (max-width: 1024px) {
        margin-top: -25px;
        background-attachment: scroll;
        background-position: 75% 50%;
    }
    @media (min-width: 1199px) and (orientation: landscape) {
        margin-top: -15px;
        margin-left: -20px;
    }
}

.header-overlay {
    background-color: rgba(31, 31, 31, 0.75);
    padding: 300px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 0px !important;
    margin-bottom: 0px !important;
    @media (max-width: 767px) {
        padding: 75px 0px;
    }
}

.logo {
    width: 100%;
    max-width: 177px;
    margin-top: 10px;
    @media (max-width: 767px) {
        width: 100%;
        max-width: 177px;
        margin-top: 10px;
        margin-left: 5px;
    }
}

.logo-large {
    top: 0px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    margin-bottom: 150px;
    @media (max-width: 767px) {
        top: 25px;
    }
    @media (max-width: 1199px) {
        color: #fff;
        font-size: 1.15em;
    }
}

.logo-large p {
    color: #fff;
    font-size: 1.5em;
    line-height: 45px;
    margin-bottom: 30px;
    padding-left: 10px;
    @media (max-width: 767px) {
        padding-top: 45px;
        text-align: center;
    }
}

@media (max-width: 1199px) {
    .logo-large p {
        color: #fff;
        font-size: 1.15em;
        line-height: 40px;
    }
}

.container-fluid {
    padding: 0px;
}


/* =========== Buttons ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: $primary;
    border-color: $wht;
    color: $wht;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download:hover {
    background-color: $secondary;
    color: $wht;
}

.btn-tour,
.btn-tour:focus,
.btn-tour:active,
.btn-tour:visited {
    color: $primary;
    margin-left: 20px;
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 500;
    background: transparent;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
}


/* =========== Form Area =========== */

.form-area {
    background: rgb(176, 212, 227);
    background: -moz-linear-gradient(top, rgb(176, 212, 227) 0%, rgb(136, 186, 207) 100%);
    background: -webkit-linear-gradient(top, rgb(176, 212, 227) 0%, rgb(136, 186, 207) 100%);
    background: linear-gradient(to bottom, rgb(176, 212, 227) 0%, rgb(136, 186, 207) 100%);
    padding: 60px 0px;
    @media (max-width: 1024px) {
        text-align: center;
    }
    @media (max-width: 1199px) and (orientation: landscape) {
        margin-bottom: 0px;
    }
}

.form-area h1 {
    color: $primary;
    position: relative;
    margin-bottom: 40px;
}

.form-area p {
    color: $secondary;
}

.form-area img {
    padding-bottom: 0px;
    @media (max-width: 767px) {
        padding: 50px 0px;
    }
}


/* =========== Features =========== */

.features {
    background-image: url("../img/recent-members-background.jpg");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: 100% 40%;
    z-index: -1000;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    border-top: 15px solid #009cb2;
    border-bottom: 15px solid #009cb2;
    @media (max-width: 1199px) {
        background-position: 50% 40%;
    }
}

.features-overlay {
    background-color: rgba(31, 31, 31, 0.75);
    padding: 150px 0px;
    margin: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 0px !important;
    @media (max-width: 767px) {
        padding: 50px 0px;
    }
}

.features h1 {
    color: $primary;
    text-align: center;
    position: relative;
    margin-bottom: 100px;
}

.features h3 {
    color: $primary;
    position: relative;
    margin-bottom: 40px;
}

.features p {
    color: $wht;
}

.features div i {
    display: block;
    font-size: 8em;
    margin: 0 auto;
    color: $primary;
    @media (max-width: 767px) {
        padding-top: 25px;
    }
}

.join-now-btn {
    margin-top: 150px;
}


/* =========== MAKE A MATCH =========== */

.make-a-match {
    background: rgb(176, 212, 227);
    background: -moz-linear-gradient(top, rgb(176, 212, 227) 0%, rgb(136, 186, 207) 100%);
    background: -webkit-linear-gradient(top, rgb(176, 212, 227) 0%, rgb(136, 186, 207) 100%);
    background: linear-gradient(to bottom, rgb(176, 212, 227) 0%, rgb(136, 186, 207) 100%);
    padding: 50px;
    @media (max-width: 1199px) {
        padding: 15px;
    }
}

.make-a-match h1 {
    color: $primary;
    text-align: center;
    position: relative;
    margin-bottom: 100px;
    @media (max-width: 767px) {
        margin-bottom: 0px;
    }
    @media (max-width: 1024px) {
        margin-bottom: 25px;
    }
}

.make-a-match h3 {
    color: $primary;
    position: relative;
    margin-bottom: 10px;
    @media (max-width: 1024px) {
        text-align: center;
    }
}

.make-a-match p {
    color: $wht;
    color: $secondary;
    @media (max-width: 1024px) {
        text-align: center;
        margin-bottom: 50px;
    }
}

.make-a-match img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: 350px;
    margin-top: 25px;
    @media (max-width: 1198px) {
        max-width: 300px;
        margin-top: 100px;
    }
    @media (max-width: 1023px) {
        max-width: 250px;
        display: block;
        margin: 0 auto;
    }
}

body.modal-open {
    overflow: auto;
    padding-right: 0 !important;
}